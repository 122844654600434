import { AxiosInstance } from 'axios';
import { authApi, mapBoxApi } from 'config/api';
import { Incident, MapAsset, MapSearchResult, NearbyFeature } from 'types';

import { transformToOldGeoSearchShape } from './mapApi.helpers';

const MAPBOX_QUERY = `access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true&fuzzyMatch=true&country=US,AU,CA&language=en`;

export default class MapApi {
  mapBoxApi: AxiosInstance;
  api: AxiosInstance;

  constructor() {
    this.mapBoxApi = mapBoxApi;
    const api = authApi();
    this.api = api;
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.mapBoxApi = axiosInstance;
  }

  // Mapbox API to search location on map
  async apiGetSearchLocation(query: string): Promise<MapSearchResult> {
    try {
      const { data } = await this.mapBoxApi.get(`/mapbox.places/${query}.json?${MAPBOX_QUERY}`);

      return data;
    } catch (e) {
      return null;
    }
  }
  /**
   * @description Searches for assets nearby a given incidentId and search radius
   * **NOTE** DO NOT CALL THIS DIRECTLY FROM COMPONENT - USE RECOIL SELECTOR rsmFetchNearByAssetsByIncidentId
   */
  async apiGetNearByAssets(
    /** The id of the incident*/
    incidentId: Incident['id'],
    /** Radius to search around from the point in km */
    radius = 2000,
  ): Promise<MapAsset[]> {
    if (!incidentId) {
      return null;
    }

    try {
      const { data } = await this.api.get(`/nearbyfeatures?incidentId=${incidentId}&radius=${radius}`);

      const assets = transformToOldGeoSearchShape(data[Object.keys(data)[0]] as NearbyFeature[]);

      return assets;
    } catch (e) {
      return null;
    }
  }
}
