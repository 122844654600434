import { useEffect, useRef, useState } from 'react';
import MESSAGES from 'config/messages';
import { rscGlobalSnackbar, rstTaskBoundingBox, rstTaskImageUrl, rstTaskSource } from 'data';
import { reportErrorMessage } from 'pano360/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BBox, Severity } from 'types';

import { useTheme } from '@mui/material/styles';

interface ScaleRatios {
  heightRatio: number;
  widthRatio: number;
}

export const getScaleRatios = ({
  ctx,
  image,
}: {
  image: HTMLImageElement;
  ctx: CanvasRenderingContext2D;
}): ScaleRatios => {
  return {
    heightRatio: ctx.canvas.height / image.naturalHeight,
    widthRatio: ctx.canvas.width / image.naturalWidth,
  };
};

export const drawRectangle = ({
  ctx,
  boundingBox,
  image,
  color,
}: {
  ctx: CanvasRenderingContext2D;
  boundingBox: BBox;
  image: HTMLImageElement;
  color: string;
}) => {
  const scaleRatios = getScaleRatios({ ctx, image });

  const scaledWidth = (boundingBox.x2 - boundingBox.x1) * scaleRatios.widthRatio;
  const scaledHeight = (boundingBox.y2 - boundingBox.y1) * scaleRatios.heightRatio;
  const scaledX1 = boundingBox.x1 * scaleRatios.widthRatio;
  const scaledY1 = boundingBox.y1 * scaleRatios.heightRatio;

  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.strokeRect(scaledX1, scaledY1, scaledWidth, scaledHeight);
};

/**
 * This gets the new dimensions for the canvas element
 * - Prevents the canvas from getting set to 0 x 0 in case the canvas is not visible
 */
export const getNewCanvasDimensions = (canvas: HTMLCanvasElement) => {
  const rect = canvas.getBoundingClientRect();

  return {
    width: rect.width || canvas.width,
    height: rect.height || canvas.height,
  };
};

/**
 * This manages all the canvas logic for the Detection Image Canvas
 * @param canvasRef - The canvas element
 */
export const useDetectionImageCanvas = (canvasRef: React.MutableRefObject<HTMLCanvasElement>) => {
  const boundingBox = useRecoilValue(rstTaskBoundingBox);
  const imageUrl = useRecoilValue(rstTaskImageUrl);
  const setGlobalSnackbar = useSetRecoilState(rscGlobalSnackbar);
  const taskSource = useRecoilValue(rstTaskSource);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const theme = useTheme();

  const imageRef = useRef(new Image());

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const image = imageRef.current;

    /**
     * @description
     * - Sets the canvas elements size to be that of the container
     * - Paints the image
     * - Paints the bounding box
     */
    const handleImageLoad = () => {
      setIsImageLoaded(true);
      if (canvas && ctx) {
        const { width, height } = getNewCanvasDimensions(canvas);
        canvas.width = width;
        canvas.height = height;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        drawRectangle({ ctx, boundingBox, image, color: theme.palette.warning.main });
      }
    };

    if (taskSource === 'camera') {
      image.src = imageUrl;
      image.onload = handleImageLoad;
      image.onerror = () => {
        setIsImageLoaded(false);
        reportErrorMessage(`Detection Image Load Error: ${imageUrl}`);
        setGlobalSnackbar({ message: MESSAGES.DETECTION_IMAGE_FAILED_TO_LOAD, severity: Severity.Error });
      };
    }

    const handleResize = () => {
      if (isImageLoaded) {
        handleImageLoad();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [boundingBox, canvasRef, imageUrl, isImageLoaded, theme.palette.warning.main, setGlobalSnackbar, taskSource]);
};
