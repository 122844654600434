import React from 'react';

import MuiTab from '@mui/material/Tab';

import styles from './Tab.styles';

/**
 * Custom Tab component that extends the MuiTab component.
 * @returns A React component that displays a tab.
 */
const Tab = ({ ...props }) => {
  return <MuiTab sx={styles.tab} {...props} />;
};

export default Tab;
