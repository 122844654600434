import React from 'react';
import { ReactComponent as WindSpeed } from 'assets/icons/wind-speed.svg';
import { rsmMapUnits, rsmWeatherMapLayerSelection } from 'data';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UnitSystem } from 'weatherlayers-gl';

import AirIconOutlined from '@mui/icons-material/Air';
import OpacityIconOutlined from '@mui/icons-material/Opacity';
import ThermostatIconOutlined from '@mui/icons-material/Thermostat';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import SvgIcon from '@mui/material/SvgIcon';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { styles } from './WeatherMapLayersMenu.styles';
import WeatherOption from './WeatherOption';

export const WeatherMapLayersMenu = () => {
  const [weatherMapLayerSelection, setWeatherMapLayerSelection] = useRecoilState(rsmWeatherMapLayerSelection);
  const mapUnits = useRecoilValue(rsmMapUnits);
  const resolutionText = `~${mapUnits === UnitSystem.IMPERIAL ? '18mi' : '28km'} res.`;

  return (
    <Box sx={styles.menuContainer} data-cy='weather-menu-container'>
      <Box sx={styles.weatherOptionsWrapper}>
        <WeatherOption
          layerName='temperature'
          layerLabel='Temperature'
          icon={<ThermostatIconOutlined fontSize='small' sx={styles.icon} />}
          resolution={resolutionText}
        />
        <WeatherOption
          layerName='humidity'
          layerLabel='Relative humidity'
          icon={<OpacityIconOutlined fontSize='small' sx={styles.icon} />}
          resolution={resolutionText}
        />
        <WeatherOption
          layerName='wind-speed'
          layerLabel='Wind speed at 10 meters'
          icon={<SvgIcon sx={styles.icon} component={WindSpeed} />}
          resolution={resolutionText}
        />
        <WeatherOption
          layerName='wind-gust'
          layerLabel='Wind gust'
          icon={<AirIconOutlined fontSize='small' sx={styles.icon} />}
          resolution={resolutionText}
        />
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={weatherMapLayerSelection.animation === true}
            onChange={() => {
              setWeatherMapLayerSelection((prevValues) => ({
                ...prevValues,
                animation: !prevValues.animation,
              }));
            }}
          />
        }
        label={
          <Box sx={styles.layerInfo}>
            <Typography variant='body1' sx={styles.layerTitle}>
              Wind particle animation
            </Typography>
            <Typography variant='body2' sx={{ ...styles.sourceInfo, textWrap: 'nowrap' }}>
              Source: NOAA GFS {resolutionText}
            </Typography>
          </Box>
        }
      />
      <Typography component='p' variant='body2' sx={{ ...styles.sourceInfo, ...styles.disclaimer } as SxProps<Theme>}>
        Pano is not responsible for this data. Use at your own risk. Actual conditions may differ from the source.
      </Typography>
    </Box>
  );
};

export default WeatherMapLayersMenu;
