import { localStorageEffect } from 'data/effect';
import { WEATHER_STATION_PROVIDERS_FIELD, WeatherDisplayType } from 'integrations/weatherStations/config/constants';
import { WeatherProvider } from 'integrations/weatherStations/types';
import { atom, selector } from 'recoil';

/**
 * @description Atom to store the weather station providers
 */
export const rswWeatherStationProviders = atom<WeatherProvider[]>({
  key: 'rswWeatherStationProviders',
  default: [],
  effects: [localStorageEffect(WEATHER_STATION_PROVIDERS_FIELD, [])],
});

/**
 * @description Selector to check if there are weather stations. This keeps the logic here instead of in multiple components.
 */
export const rswHasWeatherStationProviders = selector<boolean>({
  key: 'rswHasWeatherStationProviders',
  get: ({ get }) => get(rswWeatherStationProviders).length > 0,
});

/**
 * @description Selector to get the active weather station provider
 * Currently only one provider is supported.
 */
export const rswActiveWeatherStationProvider = selector<WeatherProvider>({
  key: 'rswActiveWeatherStationProvider',
  get: ({ get }) => {
    const providers = get(rswWeatherStationProviders);

    if (!providers.length) {
      return null;
    }

    return providers[0];
  },
});

/**
 * @description Atom to store the visibility of weather stations
 */
export const rswShowingWeatherStations = atom({
  key: 'rswShowingWeatherStations',
  default: false,
});

/**
 * @description Atom to store the weather tab selection
 */
export const rswWeatherTabSelection = atom({
  key: 'rswWeatherTabSelection',
  default: WeatherDisplayType.FORCAST,
});

export const rsmShouldShowVisibileForcastLayers = selector<boolean>({
  key: 'rsmShouldShowVisibileForcastLayers',
  get: ({ get }) => {
    const hasWeatherStationProviders = get(rswHasWeatherStationProviders);
    const weatherTabSelection = get(rswWeatherTabSelection);

    if (!hasWeatherStationProviders) {
      return true;
    }

    return weatherTabSelection === WeatherDisplayType.FORCAST;
  },
});
