import ActiviyApi from './activity';
import AdminApi from './admin';
import AnalyticsApi from './analytics';
import AuthApi from './authApi';
import FeatureFlagsApi from './featureFlag';
import IncidentApi from './incidentApi';
import MapApi from './mapApi';
import MapLayerConfigApi from './mapLayerConfig';
import OzApi from './ozApi';
import StationApi from './stationApi';
import TaskApi from './task';
import WeatherLayersApi from './weatherLayersApi';

const ActivityApis = new ActiviyApi();
const AuthApis: AuthApi = new AuthApi();
const IncidentApis: IncidentApi = new IncidentApi();
const FeatureFlagsApis: FeatureFlagsApi = new FeatureFlagsApi();
const MapApis: MapApi = new MapApi();
const OzApis: OzApi = new OzApi();
const StationApis: StationApi = new StationApi();
const TaskApis = new TaskApi();
const WeatherLayersApis = new WeatherLayersApi();
const AnalyticsApis = new AnalyticsApi();
const MapLayerConfigApis = new MapLayerConfigApi();
const AdminApis = new AdminApi();

export {
  ActivityApis,
  AdminApis,
  AnalyticsApis,
  AuthApis,
  FeatureFlagsApis,
  IncidentApis,
  MapApis,
  MapLayerConfigApis,
  OzApis,
  StationApis,
  TaskApis,
  WeatherLayersApis,
};
