import { AxiosInstance } from 'axios';
import { authApi } from 'config/api';
import {
  AdminStation,
  FetchAdminStationsResponse,
  FetchStationsParams,
  StationStatus,
  UpdatedStationRequest,
} from 'types';

import { getUpdateStatusMethod, StationStatusMapKeys } from './adminApi.helpers';

export default class AdminApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init() {
    this.api = authApi();
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance) {
    this.api = axiosInstance;
  }

  /**
   *
   * @param params the pageSize, pageNumber, sortBy, and sortOrder query params to filter the station data
   * @note pageNumber starts from 1
   * @returns list of stations on the given page
   */

  async apiGetStations({
    pageSize,
    pageNumber,
    sortBy,
    sortOrder,
    keyword,
  }: FetchStationsParams): Promise<FetchAdminStationsResponse> {
    try {
      const { data } = await this.api.get(
        `/v2/ecs?pageSize=${pageSize}&pageNumber=${pageNumber}${sortBy ? `&sortBy=${sortBy}` : ''}${
          sortOrder ? `&sortOrder=${sortOrder}` : ''
        }${keyword ? `&keyword=${keyword}` : ''}`,
      );

      return data;
    } catch (e) {
      console.error('[Pano] API Error /ecs', e);

      throw new Error(`[Pano] API Error: ${JSON.stringify(e)}`);
    }
  }

  /**
   *
   * @param updatedStation the request body to update the station
   * @returns the updated station with all of the station details
   */
  async apiPutUpdateStation(updatedStation: UpdatedStationRequest): Promise<AdminStation> {
    try {
      const { data } = await this.api.put(`/v2/ecs/${updatedStation.id}`, updatedStation);

      return data;
    } catch (e) {
      console.error('[Pano] API Error /v2/ecs', e);

      throw new Error(`[Pano] API Error: ${JSON.stringify(e)}`);
    }
  }

  /**
   *
   * @param stationId id of the station to update the onSeason status
   * @param onSeason true if the station is on season, false otherwise
   * @returns the updated station with all of the station details
   */
  async apiPostUpdateOnSeason(stationId: number, onSeason: boolean): Promise<AdminStation> {
    try {
      const { data } = await this.api.post(`/v2/ecs/${stationId}:change-season`, { onSeason });

      return data;
    } catch (e) {
      console.error('[Pano] API Error /ecs/:change-season', e);

      throw new Error(`[Pano] API Error: ${JSON.stringify(e)}`);
    }
  }

  /**
   *
   * @param stationId id of the station to update the status
   * @param status the status to update to (e.g. active, maintenance)
   * @returns the updated station with all of the station details
   */
  async apiPostUpdateStatus(stationId: number, status: StationStatus): Promise<AdminStation> {
    try {
      const updateStatusMethod = getUpdateStatusMethod(status as StationStatusMapKeys);

      if (!updateStatusMethod) {
        throw new Error('Invalid status');
      }

      const { data } = await this.api.post(`/v2/ecs/${stationId}:${updateStatusMethod}`);

      return data;
    } catch (e) {
      console.error('[Pano] API Error /v2/ecs/:id:updateStatusMethod', e);

      throw new Error(`[Pano] API Error: ${JSON.stringify(e)}`);
    }
  }
}
