import { getIsStatusDisabled } from 'pano360/utils';
import { Incident, INCIDENT_LABEL, StationsMap } from 'types';

/**
 *
 * @param incidentItem - The incident we want to get camera names for
 * @param userStationsMap - A map of all the user stations
 * @returns {string} The name of the incident camera, with (Inactive) if it is inactive
 */
export const getIncidentCameraNames = (incidentItem: Incident, userStationsMap: StationsMap): string[] => {
  return (incidentItem?.cameras || [])
    .filter((camera) => !!camera.mark)
    .sort((camera) => (camera.id === incidentItem.primary ? -1 : 0)) // Ensure the primary camera comes first
    .map((camera) => {
      const isStationDisabled = getIsStatusDisabled(userStationsMap[camera?.id]?.status);

      if (!userStationsMap[camera?.id]?.name) {
        return '';
      }

      return `${userStationsMap[camera?.id]?.name}${isStationDisabled ? ' (Inactive)' : ''}`;
    })
    .filter((name) => Boolean(name))
    .slice(0, 5);
};

/**
 * @param label - The label of the Item
 * @returns - An array [The class assocaited with that label, The Text Color]
 */
export function getLabelClasses(label: string): string[] {
  let typeTextColor = '';
  let sourceTextColor = '';
  switch (label) {
    case INCIDENT_LABEL.DISMISSED:
    case INCIDENT_LABEL.CLOSED:
      typeTextColor = 'greys.light';
      break;
    case INCIDENT_LABEL.CONFIRMED:
      typeTextColor = 'secondary.main';
      break;
    default:
      typeTextColor = 'primary.main';
      sourceTextColor = 'primary.main';
  }

  return [typeTextColor, sourceTextColor];
}
