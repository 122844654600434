import { rsaUserCoordinateSystem, rsmIncidentMapBounds, rsmWidgetMapBounds, rssQueryStationById } from 'data';
import { getCoordinatesForDisplay } from 'pano360/utils';
import { atom, selector, selectorFamily } from 'recoil';
import { LonLat } from 'types';

export const rsmGetIsPinInIncidentMapBounds = selectorFamily<boolean, number>({
  key: 'rsmGetIsPinInIncidentMapBounds',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const bounds = get(rsmIncidentMapBounds);

      if (!bounds) {
        return false;
      }

      return bounds.contains({ lat: station?.lat, lon: station?.lon });
    },
});

export const rsmGetIsPinInWidgetMapBounds = selectorFamily<boolean, number>({
  key: 'rsmGetIsPinInWidgetMapBounds',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const bounds = get(rsmWidgetMapBounds);

      if (!bounds) {
        return false;
      }

      return bounds.contains({ lat: station?.lat, lon: station?.lon });
    },
});

/**
 * The coordinates of the dropped pin when 'drop a pin' mode is enabled
 * stringified and paired with coordinateSystem type for display in the CoordinatesDisplay component
 */
export const rsmDroppedMapPinCoordinatesForDisplay = selector({
  key: 'rsmDroppedMapPinCoordinatesForDisplay',
  get: ({ get }) => {
    const lonLat = get(rsmDroppedMapPinCoordinates);
    const coordinateSystem = get(rsaUserCoordinateSystem);

    if (!lonLat) {
      return null;
    }

    return getCoordinatesForDisplay(lonLat, coordinateSystem);
  },
});

/** Whether 'drop a pin' mode is enabled to get map coordinates */
export const rsmIsDropPinModeEnabled = atom<boolean>({
  key: 'rsmIsDropPinModeEnabled',
  default: false,
});

/** The coordinates of the dropped pin when 'drop a pin' mode is enabled */
export const rsmDroppedMapPinCoordinates = atom<LonLat>({
  key: 'rsmDroppedMapPinCoordinates',
  default: null,
});
