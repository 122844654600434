import React from 'react';
import { rsmWeatherMapLayerSelection } from 'data';
import { useRecoilState } from 'recoil';
import { WeatherLayerNames } from 'types';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';

import { styles } from './WeatherMapLayersMenu.styles';

export interface Props {
  icon: React.ReactNode;
  layerName: WeatherLayerNames;
  layerLabel: string;
  source?: string;
  resolution?: string;
}

export const WeatherOption = ({
  icon,
  layerName,
  layerLabel,
  source = 'NOAA GFS',
  resolution = '~18mi res.',
}: Props) => {
  const [weatherMapLayerSelection, setWeatherMapLayerSelection] = useRecoilState(rsmWeatherMapLayerSelection);
  const toggleWeatherMapLayersVisbility = (layerName: WeatherLayerNames) => {
    setWeatherMapLayerSelection((prevValues) => {
      if (prevValues.raster === layerName) {
        return {
          ...prevValues,
          raster: null,
        };
      }

      return {
        ...prevValues,
        raster: layerName,
      };
    });
  };

  const isSelected = weatherMapLayerSelection.raster === layerName;

  return (
    <ToggleButton
      value={layerName}
      selected={isSelected}
      aria-label={layerName}
      data-cy='map-layer-toggle-button'
      onClick={() => {
        toggleWeatherMapLayersVisbility(layerName);
      }}
      sx={styles.menuItem}
    >
      {icon}
      <Box sx={styles.layerInfo}>
        <Typography variant='body1' sx={styles.layerTitle}>
          {layerLabel}
        </Typography>
        <Box sx={styles.sourceInfo}>
          <Typography variant='body2' sx={styles.sourceInfo}>
            <span>Source: {source}</span> <span>{resolution}</span>
          </Typography>
        </Box>
      </Box>
    </ToggleButton>
  );
};

export default WeatherOption;
