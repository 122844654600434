import { useCallback } from 'react';
import { INCIDENT_LIMIT } from 'config/constants';
import {
  rsaIsFullAuth,
  rscIncidentLoadingStart,
  rsiAttachIncidentsStatus,
  rsiFeaturedIncident,
  rsiFetchIncidentsStatus,
  rsiUserIncidents,
  rsiUserIncidentsTs,
  rspFullScreenStationId,
} from 'data';
import { IncidentApis } from 'data/proxyApi';
import { applyArrayDifference, getIncidentLabel, nowMs } from 'pano360/utils';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ApiCallStatus, GetIncidentsQueryParams, Incident } from 'types';

interface Returned {
  /**
   * Fetching all incidents once
   * - Use Case: Fetch incidents on user interaction or on page load
   */
  fetchIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;
  /**
   * Fetching all incidents without updating the loading state
   * - Use Case: To fetch all incidents in the background of OCV, and update the latest_panos
   */
  fetchIncidentsQuietly?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;
  /**
   * Fetching all incidents once, and append them to any incidents in the store.
   * - Use Case: Fetch incidents on user interaction or on page load
   */
  fetchAndAppendIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;
  /**
   * Fetching additional incidents
   * - Use Case: Fetch additition incidents when polling
   */
  refreshIncidents?: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]>;

  getIncidentById?: (_id: string | number, _forceFetch?: boolean, _syncToStore?: boolean) => Promise<Incident>;

  /**
   * Attach smoke investigation to selected, base incident
   * Update the list of incidents and featured incident with the updated base incident and redirect to the base incident
   * @param shouldSimulateFailure - Used for testing to simulate failed API request
   */
  attachIncidents?: (
    baseIncidentId: number,
    smokeInvestigationId: number,
    shouldSimulateFailure?: boolean,
  ) => Promise<Incident>;

  /**
   * Detach the child incident from the base incident
   * Update the list of incidents and redirect to the detached child incident
   * @param shouldSimulateFailure - Used for testing to simulate failed API request
   */
  detachIncidents?: (originIncidentId: number, shouldSimulateFailure?: boolean) => Promise<Incident>;
}

export default function useIncidents(): Returned {
  const history = useHistory();

  const [userIncidents, setUserIncidents] = useRecoilState(rsiUserIncidents);
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const [userIncidentsTs, setUserIncidentsTs] = useRecoilState(rsiUserIncidentsTs);
  const setIncidentLoadingStart = useSetRecoilState(rscIncidentLoadingStart);
  const setFetchIncidentStatus = useSetRecoilState(rsiFetchIncidentsStatus);
  const [featuredIncident, setFeaturedIncident] = useRecoilState(rsiFeaturedIncident);
  const setAttachIncidentStatus = useSetRecoilState(rsiAttachIncidentsStatus);
  const setFullScreenStationId = useSetRecoilState(rspFullScreenStationId);

  /**
   * Used to fetch incidents once
   * - NOTE: This replaces the all the incidents in the store
   */
  const fetchIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status, view } = {}) => {
      setFetchIncidentStatus(ApiCallStatus.Loading);
      if (!isFullAuth) {
        setFetchIncidentStatus(ApiCallStatus.HasValue);

        return [];
      }

      try {
        setIncidentLoadingStart(Date.now());
        const incidents = await IncidentApis.apiGetIncidents({ limit, keyword, status, stationId, view });

        setUserIncidents(incidents);
        setUserIncidentsTs(nowMs());

        setFetchIncidentStatus(ApiCallStatus.HasValue);

        return incidents;
      } catch {
        setFetchIncidentStatus(ApiCallStatus.HasError);

        return [];
      }
    },
    [isFullAuth, setIncidentLoadingStart, setUserIncidents, setUserIncidentsTs, setFetchIncidentStatus],
  );

  /**
   * Used to fetch all incidents without loading states
   * - NOTE: This replaces the all the incidents in the store
   */
  const fetchIncidentsQuietly: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status, view } = {}) => {
      if (!isFullAuth) {
        return [];
      }

      try {
        setIncidentLoadingStart(Date.now());
        const incidents = await IncidentApis.apiGetIncidents({ limit, keyword, status, stationId, view });

        setUserIncidents(incidents);
        setUserIncidentsTs(nowMs());

        return incidents;
      } catch {
        return [];
      }
    },
    [isFullAuth, setIncidentLoadingStart, setUserIncidents, setUserIncidentsTs],
  );

  /**
   * Used to fetch incidents and add them to the store
   * - NOTE: This keeps the existing incidents in the store
   */
  const fetchAndAppendIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status, view } = {}) => {
      if (!isFullAuth) {
        return [];
      }

      setIncidentLoadingStart(Date.now());
      const incidents = await IncidentApis.apiGetIncidents({ limit, keyword, status, stationId, view });
      setUserIncidents((userIncidents) => applyArrayDifference<Incident>(userIncidents, incidents));

      setUserIncidentsTs(nowMs());

      return incidents;
    },
    [isFullAuth, setIncidentLoadingStart, setUserIncidents, setUserIncidentsTs],
  );

  /**
   * Used for polling for new incidents
   * Note: this keeps the existing incidents in the store.
   * If the queryParams only request possible or confirmed status,
   * existing incidents that have been closed or dismissed will remain in the store.
   * Skips generic error handling
   * @Note To enable filtering out incidents that have been updated but of different status
   * - We request all incidents
   * - Then filter them out client side
   */
  const refreshIncidents: (queryParams?: GetIncidentsQueryParams) => Promise<Incident[]> = useCallback(
    async ({ keyword = '', limit = INCIDENT_LIMIT, stationId, status, testHeader, view } = {}) => {
      if (!isFullAuth) {
        return [];
      }
      const diffIncidents = await IncidentApis.apiGetIncidents({
        ifRange: userIncidentsTs,
        limit,
        keyword,
        view,
        stationId,
        shouldSkipErrorHandling: true,
        testHeader,
      });

      let incidents = applyArrayDifference(userIncidents, diffIncidents);
      if (status?.length) {
        incidents = incidents.filter((incident) => {
          return status.includes(getIncidentLabel(incident));
        });
      }

      setUserIncidents(incidents);
      setUserIncidentsTs(nowMs());

      return incidents;
    },
    [isFullAuth, setUserIncidents, setUserIncidentsTs, userIncidents, userIncidentsTs],
  );

  const getIncidentById: (_id: string | number, _forceFetch?: boolean, _syncToStore?: boolean) => Promise<Incident> =
    useCallback(
      async (id, forceFetch = false, syncToStore = true) => {
        if (!forceFetch) {
          const incident = userIncidents.find((i) => i.id === id);
          if (incident) {
            return incident;
          }
        }

        const incident = await IncidentApis.apiGetIncidentById(id);
        if (syncToStore) {
          const incidents = applyArrayDifference<Incident>(userIncidents, [incident]);
          setUserIncidents(incidents);
        }

        return incident;
      },
      [setUserIncidents, userIncidents],
    );

  const attachIncidents: (
    baseIncidentId: number,
    smokeInvestigationId: number,
    shouldSimulateFailure: boolean,
  ) => Promise<Incident> = useCallback(
    async (baseIncidentId, smokeInvestigationId, shouldSimulateFailure) => {
      setAttachIncidentStatus(ApiCallStatus.Loading);

      try {
        const updatedIncident = await IncidentApis.apiPostAttachIncident(
          baseIncidentId,
          smokeInvestigationId,
          shouldSimulateFailure,
        );

        if (updatedIncident) {
          const incidents = applyArrayDifference<Incident>(userIncidents, [updatedIncident]);
          setUserIncidents(incidents);
          setFeaturedIncident(updatedIncident);

          history.push(`/incident/${baseIncidentId}?camera=${featuredIncident?.cameras[0]?.id}`);
        }

        setAttachIncidentStatus(ApiCallStatus.HasValue);

        return updatedIncident;
      } catch {
        setAttachIncidentStatus(ApiCallStatus.HasError);

        return null;
      }
    },
    [setUserIncidents, userIncidents, setFeaturedIncident, setAttachIncidentStatus, history, featuredIncident?.cameras],
  );

  const detachIncidents: (originIncidentId: number, shouldSimulateFailure: boolean) => Promise<Incident> = useCallback(
    async (originIncidentId, shouldSimulateFailure) => {
      setAttachIncidentStatus(ApiCallStatus.Loading);

      try {
        const updatedIncident = await IncidentApis.apiPostDetachIncident(originIncidentId, shouldSimulateFailure);

        if (updatedIncident) {
          const incidents = applyArrayDifference<Incident>(userIncidents, [updatedIncident]);
          setUserIncidents(incidents);
          setFeaturedIncident(updatedIncident);
          setFullScreenStationId(0);

          history.push(`/incident/${originIncidentId}`);
        }

        setAttachIncidentStatus(ApiCallStatus.HasValue);

        return updatedIncident;
      } catch {
        setAttachIncidentStatus(ApiCallStatus.HasError);

        return null;
      }
    },
    [setUserIncidents, userIncidents, setFeaturedIncident, setAttachIncidentStatus, history, setFullScreenStationId],
  );

  return {
    fetchIncidents,
    fetchIncidentsQuietly,
    fetchAndAppendIncidents,
    refreshIncidents,
    getIncidentById,
    attachIncidents,
    detachIncidents,
  };
}
