import theme from 'config/theme';

import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}
export const styles: StyleProps = {
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  weatherOptionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    margin: '12px 0',
  },
  menuItem: {
    borderColor: theme.palette.greys.border,
    color: theme.palette.greys.dark,
    justifyContent: 'flex-start',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      boxShadow: '1px 1px 12px 0px rgba(0, 0, 0, 0.12)',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primaryExtra.extraLight,
      borderColor: theme.palette.primary.main,
      color: theme.palette.greys.dark,
      outline: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primaryExtra.extraLight,
        boxShadow: '1px 1px 12px 0px rgba(0, 0, 0, 0.12)',
      },
    },
  },
  icon: {
    color: theme.palette.greys.dark,
    marginRight: 1,
  },
  layerInfo: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    justifyContent: 'center',
    width: '100%',
  },
  layerTitle: {
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: '100%',
  },
  sourceInfo: {
    color: theme.palette.greys.subtext,
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1,
    justifyContent: 'space-between',
    width: '100%',
  },
  disclaimer: {
    lineHeight: 1.3,
    marginTop: 1.25,
  },
};
