import React from 'react';
import { useWeatherStationsProviders } from 'integrations/weatherStations';

import { useUnfreezeUser } from './UserInitializer.helpers';

/**
 * This component handles initializing the user.
 * This needs to be a sibling of any component that makes API calls.
 * If a parent of this tries to make an API call on mount it will fail.
 * That's because the interceptors won't have been appended.
 * When initializing sitewide user data, include hooks here that can prefetch data.
 * This will ensure that the data is available when the component needs it without additional api calls.
 */
export const UserInitializer = () => {
  useUnfreezeUser();
  useWeatherStationsProviders();

  return <></>;
};

export default UserInitializer;
