import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  contained: (theme: Theme) => ({
    backgroundColor: theme.palette.greys.superLight,
    borderRadius: 1,
    '& .MuiTabs-indicator': {
      height: 4,
    },
  }),
};

export default styles;
