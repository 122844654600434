import { useEffect } from 'react';
import { rsaIsFullAuth } from 'data';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { WEATHER_STATION_PROVIDERS_FIELD } from 'integrations/weatherStations/config/constants';
import { rswWeatherStationProviders, WeatherStationApis } from 'integrations/weatherStations/data';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * @description Hook to fetch weather station providers and set them in the recoil state
 * @returns {Array} weatherStationProviders
 */
const useWeatherStationsProviders = () => {
  const { evaluateBooleanFlag } = useFeatureFlags();
  const isFullAuth = useRecoilValue(rsaIsFullAuth);
  const setWeatherStationProviders = useSetRecoilState(rswWeatherStationProviders);

  useEffect(() => {
    if (!isFullAuth) {
      return;
    }

    const getWeatherStationProviders = async () => {
      const localStorageProviders = window.localStorage.getItem(WEATHER_STATION_PROVIDERS_FIELD);
      if (localStorageProviders) {
        setWeatherStationProviders(JSON.parse(localStorageProviders));
      } else {
        try {
          const providers = await WeatherStationApis.fetchWeatherProviders();
          setWeatherStationProviders(providers);
        } catch (error) {
          console.error('[Pano] Failed to set weather providers:', error);
        }
      }
    };

    // TODO: Remove this once released: RD-6923
    evaluateBooleanFlag('FE-Weather-Stations', false).then((isEnabled) => {
      if (isEnabled) {
        getWeatherStationProviders();
      }
    });
  }, [evaluateBooleanFlag, setWeatherStationProviders, isFullAuth]);
};

export default useWeatherStationsProviders;
