import { atom, selector } from 'recoil';
import { AdminStation, ApiCallStatus, Pagination } from 'types';

/**
 * API call status of fetching stations for the admin center
 */
export const rsaFetchStationsStatus = atom<ApiCallStatus>({
  key: 'rsaFetchStationsStatus',
  default: ApiCallStatus.Pending,
});

/**
 * The list of stations displayed in the admin center
 */
export const rsaStationList = atom<AdminStation[]>({
  key: 'rsaStationList',
  default: [],
});

/**
 * Pagination data for the station list
 */
export const rsaStationListPagination = atom<Pagination>({
  key: 'rsaStationListPagination',
  default: null,
});

/**
 * The list of ids of the selected stations in the admin center
 */
export const rsaSelectedStationIds = atom<number[]>({
  key: 'rsaSelectedStationIds',
  default: [],
});

/**
 * The selected station in the admin center
 * Used to populate the form for single station update
 */
export const rsaSelectedStation = selector<AdminStation>({
  key: 'rsaSelectedStations',
  get: ({ get }) => {
    const selectedStationIds = get(rsaSelectedStationIds);
    const stationList = get(rsaStationList);

    return stationList.find((station) => station.id === selectedStationIds[0]);
  },
});

/**
 * The search keyword for filtering stations in the admin center
 */
export const rsaStationsKeywordQuery = atom<string>({
  key: 'rsaStationsKeywordQuery',
  default: '',
});
