import React from 'react';
import { WeatherProvider } from 'integrations/weatherStations/types';

import Typography from '@mui/material/Typography';

import styles from './WeatherStationMenuLabel.styles';

/**
 * @returns A React component that displays a label for the weather stations toggle.
 */
const WeatherStationMenuLabel = ({
  activeWeatherStationProvider,
}: {
  activeWeatherStationProvider: WeatherProvider;
}) => {
  return (
    <>
      <Typography sx={styles.title} variant='body1'>
        Weather stations
      </Typography>
      <Typography variant='body2'>View weather data provided by {activeWeatherStationProvider.displayName}</Typography>
    </>
  );
};

export default WeatherStationMenuLabel;
