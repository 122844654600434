import React from 'react';

import MuiTabList, { TabListProps as MuiTabListProps } from '@mui/lab/TabList';

import styles from './TabList.styles';

/**
 * @description The props for the TabList component.
 * @extends MuiTabListProps
 * @property {React.ReactNode} children - The children of the TabList component.
 * @property {string} styleVariant - The style variant for the TabList component. Please extend by union.
 */
interface TabListProps extends MuiTabListProps {
  children: React.ReactNode;
  styleVariant?: 'contained';
}

/**
 * Custom TabList component that extends the MuiTabList component.
 * This allows for custom styled version of MUI tablist component.
 * @param {TabListProps} props - The props for the TabList component.
 * @returns A React component that displays a tab list.
 */
const TabList = ({ children, styleVariant, ...props }: TabListProps) => {
  return (
    <MuiTabList sx={styles[styleVariant] || {}} {...props}>
      {children}
    </MuiTabList>
  );
};

export default TabList;
