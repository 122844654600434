import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  title: (theme: Theme) => ({
    fontWeight: theme.typography.fontWeightSemiBold,
  }),
};

export default styles;
