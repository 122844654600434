export const STATION_STATUS_MAP = {
  active: 'activate',
  maintenance: 'maintain',
  inactive: 'deactivate',
  deploy: 'deploy',
  verify: 'verify',
};

export type StationStatusMapKeys = keyof typeof STATION_STATUS_MAP;

export const getUpdateStatusMethod = (status: StationStatusMapKeys) => {
  return STATION_STATUS_MAP[status] || null;
};
