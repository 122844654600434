import React, { Suspense } from 'react';
import Loading from 'componentLibrary/Loading';
import DataConflictModal from 'components/DataConflictModal';
import FailureToLoad from 'components/FailureToLoad';
import GlobalSnackbar from 'components/GlobalSnackbar';
import Heartbeat from 'components/Heartbeat';
import PICWorkflowController from 'components/PICWorkflow/PICWorkflowController';
import ScrollToTop from 'components/ScrollToTop';
import SEO from 'components/SEO';
import UserInitializer from 'components/UserInitializer';
import theme from 'config/theme';
import ListInterval from 'pages/ListInterval';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';

import Routes from './pano360/router/Router';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <style>
          {`
          :root {
            --primary: ${theme.palette.primary.main};
            --primary-dark: ${theme.palette.primary.dark};
            --alert-red: ${theme.palette.error.main};
          }
          `}
        </style>
        <RecoilRoot>
          <Suspense fallback={<Loading />}>
            <BrowserRouter basename='/'>
              <Sentry.ErrorBoundary fallback={<FailureToLoad />}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Heartbeat />
                  <UserInitializer />
                  <ScrollToTop />
                  <SEO />
                  <PICWorkflowController>
                    <Routes />
                  </PICWorkflowController>
                  <DataConflictModal />
                  <GlobalSnackbar />
                  <ListInterval />
                </LocalizationProvider>
              </Sentry.ErrorBoundary>
            </BrowserRouter>
          </Suspense>
        </RecoilRoot>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
