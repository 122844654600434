import { countryPhones } from 'config/base';
import {
  DEFAULT_VISIBILITY_KM,
  INCIDENT_BAR_BORDER_WIDTH_SCALE,
  INCIDENT_BAR_GRADIENT_METERS,
  INCIDENT_INFINITE_END_SOLID_BAR_METERS,
  ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO,
  METERS_IN_KM,
} from 'config/constants';
import { computeDestinationPoint, findNearest, getDistance, getDistanceFromLine } from 'geolib';
import _get from 'lodash/get';
import { getLengthOfAllChevrons } from 'pano360/utils/map';
import proj4 from 'proj4';
import { GDACoordinates, Incident, IncidentCamera, LonLat, LonLatArray, PhoneCode, Station } from 'types';

/** Default precision for lat/lon (see https://en.wikipedia.org/wiki/Decimal_degrees) */
export const LAT_LON_DECIMAL_PLACES = 5;

/**
 * Get the country code from the country name.
 * @param country
 */
export const getCountryCodeFromCountry = (country: string): string => {
  const countryPhone: PhoneCode = _get(countryPhones, country.toLowerCase(), countryPhones['us']);

  return countryPhone.code;
};

/**
 * In form, a non-null phone with value just country code is considered empty.
 * @param phone
 */
export const phoneIsEmpty = (phone: string): boolean => {
  return !phone || !!Object.values(countryPhones).find((countryPhone) => countryPhone.code === phone);
};

// Camera can see 36 degree from the bearing (number is arbitrary).
export const CAMERA_IN_VIEW_CONE_ANGLE = 36;
// Error bounding range of the bearing
export const CAMERA_UNCERTAINTY_ANGLE = 0.5;

// Get angle based on mapzoom level.
// @todo remove the magic number.
export const getCameraUncertaintyAngleByMapZoom = (mapZoom: number): number => {
  if (mapZoom > 16) {
    return 0.001;
  }

  if (mapZoom > 13.5) {
    return 0.01;
  }

  if (mapZoom > 11) {
    return 0.1;
  }

  return CAMERA_UNCERTAINTY_ANGLE;
};

function roundAngle(angle: number): number {
  return (angle + 360) % 360;
}

function lngLatToArray(lngLat: { lon: number; lat: number }): LonLatArray {
  return [lngLat.lon, lngLat.lat];
}

/** Takes a LonLatArray and converts it to LonLat */
export const lonLatFromArray = (lngLatArray: LonLatArray): LonLat => {
  return { lon: lngLatArray[0], lat: lngLatArray[1] };
};

/**
 * When drawing our cones, boxes and circles, we want to do them for the minimum visibility of each camera
 * @param station The station we want to get the minimum visibility for
 * @returns The smallest visibility for any camera for a station in meters
 */
export const getMinStationVisibilityInM = (station: Station) => {
  const visibilities = station?.cameras?.map((camera) => camera.visibility) || [DEFAULT_VISIBILITY_KM];

  return Math.min(...visibilities) * METERS_IN_KM;
};

/**
 * Calculates a new Lon Lat from the current Lon Lat at distance and bearing from the original
 * - This calculation happens with the [Haversine formula](https://en.wikipedia.org/wiki/Haversine_formula)
 * @param LonLat The lon lat of the start point
 * @param distance the distance away from the start point to get the new point
 * @param bearing the angle (0-360) to traverse to get the new point
 * @returns the LonLat of the new point
 */
export const computeDestinationPointLonLat = ({ lon, lat }: LonLat, distance: number, bearing: number) => {
  const { longitude, latitude } = computeDestinationPoint({ latitude: lat, longitude: lon }, distance, bearing);

  return {
    lon: longitude,
    lat: latitude,
  };
};

/**
 * The distance of a point to the nearest point on a line
 * @param point the point to measure the distance from
 * @param lineStart one point on the line
 * @param lineEnd another point on the line
 */
export const getDistanceFromLineLonLat = (point: LonLat, lineStart: LonLat, lineEnd: LonLat) => {
  return getDistanceFromLine(
    { latitude: point.lat, longitude: point.lon },
    { latitude: lineStart.lat, longitude: lineStart.lon },
    { latitude: lineEnd.lat, longitude: lineEnd.lon },
    0.1,
  );
};

/**
 * Gets the nearest point in a list of points to a given coordinate
 * @param coordinate the coordinate to find the nearest point to
 * @param points the list of points to find the nearest point in
 */
export const findNearestPointLatLon = (coordinate: LonLat, points: LonLat[]) => {
  const { longitude, latitude } = findNearest(
    { latitude: coordinate.lat, longitude: coordinate.lon },
    points.map(({ lat, lon }) => ({ latitude: lat, longitude: lon })),
  ) as { latitude: number; longitude: number };

  return {
    lon: longitude,
    lat: latitude,
  };
};

/**
 * The distance between two points in meters
 * @param point1 The first point
 * @param point2 The second point
 */
export const getDistanceLatLon = (point1: LonLat, point2: LonLat): number => {
  return getDistance({ latitude: point1.lat, longitude: point1.lon }, { latitude: point2.lat, longitude: point2.lon });
};

/**
 * @description The width in meters of an incident bar
 * @note Uses magic numbers to approximate appropriate width at different zoom levels
 */
export const getIncidentBarCenterToEdgeWidth = (zoom: number) => {
  // TODO: Replace math.min with zoom in [RD-5880]
  return (zoom > 10 ? 800000 : 600000) / Math.pow(2, Math.min(zoom, 15));
};

/**
 * @description The width in meters of an incident bar
 * @note Uses magic numbers to approximate appropriate width at different zoom levels
 */
export const getIncidentBarWidth = (zoom: number) => {
  return (zoom > 10 ? 800000 : 600000) / Math.pow(2, zoom);
};

/**
 * Gets the latitute and longitude of points on a rectangle to display on a map
 */
export const getRectanglePoints = ({
  calculationStartPoint,
  start,
  end,
  bearing,
  centerToEdgeWidth,
}: {
  /** the point from which polygon points are computed from */
  calculationStartPoint: LonLat;
  /** distance in meters to start of rectangle from centroid */
  start: number;
  /** distance in meters to end of rectangle from centroid */
  end: number;
  /** bearing of rectangle midline from centroid */
  bearing: number;
  /** width from the center point to edge of rectangle  */
  centerToEdgeWidth: number;
}) => {
  const clockwiseBearing = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat(calculationStartPoint, centerToEdgeWidth, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    centerToEdgeWidth,
    counterClockwiseBearing,
  );

  const point1 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point3 = computeDestinationPointLonLat(clockwisePoint, end, bearing);
  const point4 = computeDestinationPointLonLat(counterClockwisePoint, end, bearing);

  return [point1, point2, point3, point4, point1].map(lngLatToArray);
};

/**
 * Gets the latitute and longitude of points on a triangle to display on a map
 */
export const getTrianglePoints = ({
  calculationStartPoint,
  start,
  bearing,
  centerToEdgeWidth,
}: {
  /** the point from which polygon points are computed from */
  calculationStartPoint: LonLat;
  /** distance in meters to start of feature from centroid */
  start: number;
  /** bearing of feature midline from centroid */
  bearing: number;
  /** width from the triangle base center to edge  */
  centerToEdgeWidth: number;
}) => {
  const clockwiseBearing = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat(calculationStartPoint, centerToEdgeWidth, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    centerToEdgeWidth,
    counterClockwiseBearing,
  );
  const point1 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);
  const point3 = computeDestinationPointLonLat(calculationStartPoint, start + centerToEdgeWidth, bearing);

  return [point1, point2, point3, point1].map(lngLatToArray);
};

/**
 * Gets the length that is truncated from the start of the slant
 * and the bearing of point 2 with that truncation
 * @note Start truncation only happens for slants moving backwards,
 * towards the incident
 */
export const getSlantStartTruncationLengthAndBearing = ({
  slantStart,
  furthestRectangleEnd,
  hasPointedTip,
  calculationStartPoint,
  width,
  bearing,
  slantedLineBearing,
}: {
  /** the distance in meters to start of feature from the calculation start point */
  slantStart: number;
  /**
   * the end of the rectangular features of the incident, used to see
   * if the slant passes the end of the fade or the end of the bar with a pointed tip
   */
  furthestRectangleEnd: number;
  /** Whether the incident has a infinite end tip */
  hasPointedTip: boolean;
  /** the location of the start of the edge used to create slants */
  calculationStartPoint: LonLat;
  /** the width of the incident bar */
  width: number;
  /** the bearing of the incident bar */
  bearing: number;
  /** the bearing of the slant */
  slantedLineBearing: number;
}) => {
  const vectorOfSlantInIncidentBearingDirection = width;
  const defaultSlantLength = width * ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO;

  const isTruncatedAtBeginningToTip = slantStart > furthestRectangleEnd && hasPointedTip;
  const isTruncatedAtBeginningToEdge = slantStart > furthestRectangleEnd && !hasPointedTip;

  if (isTruncatedAtBeginningToTip) {
    const tipSidePoint1 = computeDestinationPointLonLat(calculationStartPoint, furthestRectangleEnd, bearing);
    const tipSidePoint2 = computeDestinationPointLonLat(tipSidePoint1, defaultSlantLength, slantedLineBearing + 90);

    const slantStartPoint = computeDestinationPointLonLat(calculationStartPoint, slantStart, bearing);

    const truncationLength = getDistanceFromLineLonLat(slantStartPoint, tipSidePoint1, tipSidePoint2);
    const truncationBearing = slantedLineBearing + 90;

    return { truncationLength, truncationBearing };
  } else if (isTruncatedAtBeginningToEdge) {
    const truncationPercent = (slantStart - furthestRectangleEnd) / vectorOfSlantInIncidentBearingDirection;
    const truncationLength = truncationPercent * defaultSlantLength;
    const truncationBearing = bearing - 90;

    return { truncationLength, truncationBearing };
  }

  return { truncationLength: 0, truncationBearing: bearing };
};

/**
 * Gets the length that is truncated from the end of the slant
 * and the bearing of point 4 with that truncation
 * @note If the slant is moving forward, the end is away from the incident,
 * if the slant is moving backward, the end is towards the incident
 */
export const getSlantEndTruncationLengthAndBearing = ({
  slantStart,
  directionalEnd,
  furthestRectangleEnd,
  hasPointedTip,
  calculationStartPoint,
  width,
  bearing,
  slantedLineBearing,
  isClockwiseEdge,
  point1,
}: {
  /** the distance of the beginning of the feature from the calculation start point */
  slantStart: number;
  /** the end of the features, directionally. < start if clockwise, > start if counter clockwise */
  directionalEnd: number;
  /** the furthest rectangle feature end from the calculation start point */
  furthestRectangleEnd: number;
  /** whether the feature has an infinite end */
  hasPointedTip: boolean;
  /** the point at which the feature is calculated from */
  calculationStartPoint: LonLat;
  /** the width of the incident bar */
  width: number;
  /** the bearing of the incident */
  bearing: number;
  /** the bearing of the slant */
  slantedLineBearing: number;
  /** whether the slant starts on the clockwise or counterclockwise edge of the incident bar */
  isClockwiseEdge: boolean;
  /** the slant's first point */
  point1: LonLat;
}) => {
  const vectorOfSlantInIncidentBearingDirection = width;
  const defaultSlantLength = width * ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO;
  const isSlantExceedingEnd = Math.abs(directionalEnd - slantStart) < vectorOfSlantInIncidentBearingDirection;

  const isTruncatedAtEndToTip = isSlantExceedingEnd && hasPointedTip && !isClockwiseEdge;
  const isTruncatedAtEndToEdge = isSlantExceedingEnd && (isClockwiseEdge || !hasPointedTip);

  if (isTruncatedAtEndToTip) {
    const tipSidePoint1 = computeDestinationPointLonLat(calculationStartPoint, furthestRectangleEnd, bearing);
    const tipApexPoint = computeDestinationPointLonLat(tipSidePoint1, defaultSlantLength * 0.5, slantedLineBearing);
    const tipSidePoint2 = computeDestinationPointLonLat(
      tipApexPoint,
      defaultSlantLength * 0.5,
      slantedLineBearing + 90,
    );

    const truncationLength = defaultSlantLength - getDistanceFromLineLonLat(point1, tipApexPoint, tipSidePoint2);
    const truncationBearing = slantedLineBearing - 90;

    return { truncationLength, truncationBearing };
  } else if (isTruncatedAtEndToEdge) {
    const truncationPercent = 1 - Math.abs(directionalEnd - slantStart) / vectorOfSlantInIncidentBearingDirection;
    const truncationLength = truncationPercent * defaultSlantLength;
    const truncationBearing = bearing - 90;

    return { truncationLength, truncationBearing };
  }

  return { truncationLength: 0, truncationBearing: bearing };
};

/** Gets the slant's point on the first edge, or near if truncated */
export const getPoint1 = (
  calculationStartPoint: LonLat,
  slantStart: number,
  bearing: number,
  slantedLineBearing: number,
  truncationLength: number,
) => {
  const pointOnEdge = computeDestinationPointLonLat(calculationStartPoint, slantStart, bearing);

  if (truncationLength > 0) {
    return computeDestinationPointLonLat(pointOnEdge, truncationLength, slantedLineBearing);
  }

  return pointOnEdge;
};

/** Gets the slant's point near point 1 */
export const getPoint2 = (
  point1: LonLat,
  truncationBearing: number,
  slantedLineBearing: number,
  widthOfBorder: number,
) => {
  const widthOfAngledBorder = widthOfBorder * ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO;
  if ((truncationBearing - slantedLineBearing) % 90 === 0) {
    return computeDestinationPointLonLat(point1, widthOfBorder, truncationBearing);
  }

  return computeDestinationPointLonLat(point1, widthOfAngledBorder, truncationBearing);
};

/** Gets the slant's point on or near the second edge, or near if truncated */
export const getPoint3 = (
  point1: LonLat,
  width: number,
  slantedLineBearing: number,
  startTruncationLength: number,
  endTruncationLength: number,
) => {
  const defaultSlantLength = width * ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO;
  const lengthOfSlantedLine = defaultSlantLength - startTruncationLength - endTruncationLength;

  return computeDestinationPointLonLat(point1, lengthOfSlantedLine, slantedLineBearing);
};

/** Gets the slant's point near point 3 */
export const getPoint4 = (point3: LonLat, bearing: number, slantedLineBearing: number, widthOfBorder: number) => {
  const widthOfAngledBorder = widthOfBorder * ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO;
  if ((bearing - slantedLineBearing) % 90 === 0) {
    return computeDestinationPointLonLat(point3, widthOfBorder, bearing);
  }

  return computeDestinationPointLonLat(point3, widthOfAngledBorder, bearing);
};

interface SlantedLinePointProps {
  /** the point from which polygon points are computed from */
  calculationStartPoint: LonLat;
  /** the location of the end of the solid or fade sections, whichever is further */
  furthestRectangleEnd: number;
  /** distance in meters to start of the feature */
  slantStart: number;
  /** distance in meters to end of possible slanted features */
  directionalEnd: number;
  /** bearing of incident */
  bearing: number;
  /** the full width of the incident bar  */
  width: number;
  /** Whether the slant starts on the clockwise edge and
   * ends on the counterClockwise edge or vice versa */
  isClockwiseEdge?: boolean;
  /** The angle of the slanted line */
  slantAngle: number;
  /** Whether the incident bar has the pointed tip,
   * used to extend the slanted line past the range end  */
  hasPointedTip: boolean;
}

/**
 * Gets the latitute and longitude of points on a slanted line to display on a map
 */
export const getSlantedLinePoints = ({
  calculationStartPoint,
  furthestRectangleEnd,
  slantStart,
  directionalEnd,
  bearing,
  width,
  isClockwiseEdge,
  hasPointedTip,
}: SlantedLinePointProps) => {
  const slantedLineBearing = isClockwiseEdge ? bearing - 135 : bearing + 45;
  const widthOfBorder = width / INCIDENT_BAR_BORDER_WIDTH_SCALE;
  const startTruncation = getSlantStartTruncationLengthAndBearing({
    slantStart,
    furthestRectangleEnd,
    hasPointedTip,
    calculationStartPoint,
    width,
    bearing,
    slantedLineBearing,
  });

  const point1 = getPoint1(
    calculationStartPoint,
    slantStart,
    bearing,
    slantedLineBearing,
    startTruncation.truncationLength,
  );

  const point2 = getPoint2(point1, startTruncation.truncationBearing, slantedLineBearing, widthOfBorder);

  const endTruncation = getSlantEndTruncationLengthAndBearing({
    slantStart,
    directionalEnd,
    furthestRectangleEnd,
    hasPointedTip,
    calculationStartPoint,
    width,
    bearing,
    slantedLineBearing,
    isClockwiseEdge,
    point1,
  });

  const point3 = getPoint3(
    point1,
    width,
    slantedLineBearing,
    startTruncation.truncationLength,
    endTruncation.truncationLength,
  );

  const point4 = getPoint4(point3, endTruncation.truncationBearing, slantedLineBearing, widthOfBorder);

  return [point1, point2, point4, point3, point1].map(lngLatToArray);
};

/**
 * Gets the latitute and longitude of points on a chevron to display on a map
 */
export const getChevronPoints = ({
  calculationStartPoint,
  start,
  bearing,
  centerToEdgeWidth,
}: {
  /** the point from which polygon points are computed from */
  calculationStartPoint: LonLat;
  /** distance in meters to start of feature from centroid */
  start: number;
  /** bearing of feature midline from centroid */
  bearing: number;
  /** the width from the triangle base center to edge */
  centerToEdgeWidth: number;
}) => {
  const clockwiseBearing: number = bearing + 90;
  const counterClockwiseBearing = bearing - 90;

  const clockwisePoint = computeDestinationPointLonLat(calculationStartPoint, centerToEdgeWidth, clockwiseBearing);
  const counterClockwisePoint = computeDestinationPointLonLat(
    calculationStartPoint,
    centerToEdgeWidth,
    counterClockwiseBearing,
  );
  const chevronWidth = (centerToEdgeWidth * 2) / INCIDENT_BAR_BORDER_WIDTH_SCALE;
  const hypotenuse = ISOCOLES_RIGHT_TRIANGLE_HYPOTENUSE_RATIO * chevronWidth;

  const point1 = computeDestinationPointLonLat(clockwisePoint, start, bearing);
  const point2 = computeDestinationPointLonLat(calculationStartPoint, start + centerToEdgeWidth, bearing);
  const point3 = computeDestinationPointLonLat(counterClockwisePoint, start, bearing);

  const point4 = computeDestinationPointLonLat(point3, chevronWidth, bearing + 135);
  const point5 = computeDestinationPointLonLat(point2, hypotenuse, bearing - 180);
  const point6 = computeDestinationPointLonLat(point1, chevronWidth, bearing - 135);

  return [point1, point2, point3, point4, point5, point6, point1].map(lngLatToArray);
};

/** Gets the points of a chevron that form an outline stroke around a set of chevronPoints */
export const getChevronOutlinePoints = (chevronPoints: LonLatArray[], bearing: number, centerToEdgeWidth: number) => {
  const chevronWidth = centerToEdgeWidth / INCIDENT_BAR_BORDER_WIDTH_SCALE;
  const distanceToCorners = chevronWidth * Math.sqrt(2);

  const chevronLngLat = chevronPoints.map(lonLatFromArray);

  const outlinePoint1 = computeDestinationPointLonLat(chevronLngLat[0], distanceToCorners, bearing + 90);
  const outlinePoint2 = computeDestinationPointLonLat(chevronLngLat[1], distanceToCorners, bearing);
  const outlinePoint3 = computeDestinationPointLonLat(chevronLngLat[2], distanceToCorners, bearing - 90);

  const outlinePoint4 = computeDestinationPointLonLat(chevronLngLat[3], distanceToCorners, bearing - 180);
  const outlinePoint5 = computeDestinationPointLonLat(chevronLngLat[4], distanceToCorners, bearing - 180);
  const outlinePoint6 = computeDestinationPointLonLat(chevronLngLat[5], distanceToCorners, bearing - 180);

  return [outlinePoint1, outlinePoint2, outlinePoint3, outlinePoint4, outlinePoint5, outlinePoint6, outlinePoint1].map(
    lngLatToArray,
  );
};

// Polygon points to draw Incident Direction Line
export function getRectPointsLatLng(
  incident: Incident,
  station: Station,
  incidentCamera: IncidentCamera,
  zoom: number,
): LonLatArray[] {
  const widthToEdge = getIncidentBarWidth(zoom);
  const distance = getMinStationVisibilityInM(station);
  const { bearing }: IncidentCamera = incidentCamera;
  const { lat: cameraLat, lon: cameraLng }: Station = station;
  if (bearing === null) {
    console.warn('No bearing found');
  }

  const point1Bearing: number = roundAngle(bearing + 90);
  const point2Bearing: number = roundAngle(bearing - 90);
  const point1 = computeDestinationPointLonLat({ lat: cameraLat, lon: cameraLng }, widthToEdge, point1Bearing);

  const point2 = computeDestinationPointLonLat({ lon: cameraLng, lat: cameraLat }, widthToEdge, point2Bearing);
  const point3 = computeDestinationPointLonLat({ lon: point2.lon, lat: point2.lat }, distance, bearing);
  const point4 = computeDestinationPointLonLat({ lon: point1.lon, lat: point1.lat }, distance, bearing);

  return [point1, point2, point3, point4, point1].map(lngLatToArray);
}

/**
 * @returns The Polygon points to draw Incident Uncertainty Cone and Incident Direction Line map layer
 */
export function getPolygonPointsLatLng({
  incidentCamera,
  station,
  coneAngle = CAMERA_IN_VIEW_CONE_ANGLE,
  canvasBearing = null,
}: {
  /** The camera associated with the incident */
  incidentCamera?: IncidentCamera;
  /** The station we're creating the polygon for*/
  station: Station;
  /** The number of degrees of the apex angle of the cone. ie. The inside angle of the pointy part of the cone */
  coneAngle?: number;
  /** The bearing of the line to draw (0-360 degrees) */
  canvasBearing?: number;
}): LonLatArray[] {
  const halfAngleOfAreaInView: number = coneAngle / 2;
  const visibility = getMinStationVisibilityInM(station);
  // @doc https://juejin.cn/post/6844904179387858951
  const distance = visibility / Math.cos(((2 * Math.PI) / 360) * halfAngleOfAreaInView);

  const { lat: cameraLat, lon: cameraLng } = station;
  const bearingAngle: number = canvasBearing || incidentCamera?.bearing;

  return [
    { lat: cameraLat, lon: cameraLng },
    computeDestinationPointLonLat(
      { lon: cameraLng, lat: cameraLat },
      distance,
      roundAngle(bearingAngle - halfAngleOfAreaInView),
    ),
    computeDestinationPointLonLat(
      { lon: cameraLng, lat: cameraLat },
      distance,
      roundAngle(bearingAngle + halfAngleOfAreaInView),
    ),
    { lat: cameraLat, lon: cameraLng },
  ].map(lngLatToArray);
}

/**
 * Gets the perimeter points of a polygon that approximates a circle to represent the viewshed
 * @note A polygon is used rather than a circle becuase mapbox
 * circle's pixel radius does not scale with zoom
 */
export const getStationViewshedPerimeterPoints = (
  /** The lon/lat of the station */
  stationLonLat: LonLat,
  /** the radius of the viewshed */
  viewshedRadius: number,
) => {
  const { lat, lon } = stationLonLat;
  const area = [];
  for (let angle = 0; angle < 360; angle += 1) {
    const point = computeDestinationPointLonLat({ lon, lat }, viewshedRadius, angle);
    area.push([point.lon, point.lat]);
  }
  const _point: LonLat = computeDestinationPointLonLat({ lon, lat }, viewshedRadius, 0);
  area.push([_point.lon, _point.lat]);

  return area;
};

export function getGDACoordinatesFromLonLat(lonLat: LonLat): GDACoordinates {
  const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
  const utmZone = Math.floor((lonLat.lon + 180) / 6) + 1;
  const gda2020Projection = `+proj=utm +zone=${utmZone} +south +datum=GDA2020 +units=m +no_defs`;

  const gda2020Coordinates = proj4(wgs84Projection, gda2020Projection, [lonLat.lon, lonLat.lat]);

  const roundedGDA2020Coordinates: GDACoordinates = {
    easting: Math.round(gda2020Coordinates[0]),
    northing: Math.round(gda2020Coordinates[1]),
    zone: utmZone,
  };

  return roundedGDA2020Coordinates;
}

/** Returns the distance of the end of the last incident bar feature */
export const getIncidentBarLength = (
  zoom: number,
  station: Station,
  incident: Incident,
  incidentCamera: IncidentCamera,
) => {
  const stationCoordinates = { lat: station.lat, lon: station.lon };
  const incidentCoordinates = { lat: incident.lat, lon: incident.lon };
  const width = getIncidentBarCenterToEdgeWidth(zoom);
  const { geolocation } = incidentCamera;

  const isIncidentTriangulated = incidentCoordinates.lat && incidentCoordinates.lon;
  const isIncidentUncalibrated = !geolocation || (geolocation.nearDistanceKm === 0 && !geolocation.farDistanceKm);

  if (isIncidentTriangulated) {
    return getDistanceLatLon(incidentCoordinates, stationCoordinates);
  } else if (isIncidentUncalibrated) {
    return getLengthOfAllChevrons(width);
  }
  const isInfiniteEnd = !geolocation.farDistanceKm;
  const nearDistanceMeters = geolocation.nearDistanceKm * METERS_IN_KM;
  const farDistanceMeters = geolocation.farDistanceKm * METERS_IN_KM;
  const lengthOfInfiniteEndWithTip = INCIDENT_INFINITE_END_SOLID_BAR_METERS + width * 3;

  return isInfiniteEnd
    ? nearDistanceMeters + lengthOfInfiniteEndWithTip
    : farDistanceMeters + INCIDENT_BAR_GRADIENT_METERS;
};
