import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default styles;
