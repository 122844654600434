import { TILES_URL } from 'config/base';
import { LayerProps } from 'react-map-gl';
import { atom, selector, selectorFamily } from 'recoil';
import { ApiCallStatus, MapLayerConfig } from 'types';

import { rsmMapLayersVisibility } from '../mapLayers';

/**
 * The map layer configs available for the current user
 */
export const rsmMapLayerConfigs = atom<MapLayerConfig[]>({
  key: 'rsmMapLayerConfigs',
  default: [],
});

/**
 * @description The status of fetching map layer configs
 */
export const rsmFetchMapLayerConfigStatus = atom<ApiCallStatus>({
  key: 'rsmFetchMapLayerConfigStatus',
  default: ApiCallStatus.Pending,
});

/**
 * The ttl for the signed cookie for each map layer
 */
export const rsmMapLayersSignedCookieTTLs = atom<{ [key: MapLayerConfig['id']]: number }>({
  key: 'rsmMapLayersSignedCookieTTLs',
  default: {},
});

/**
 * Gets the ttl for the signed cookie for the given map layer
 */
export const rsmMapLayersSignedCookieTTLById = selectorFamily<number, MapLayerConfig['id']>({
  key: 'rsmMapLayersSignedCookieTTLById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayersSignedCookieTTLs = get(rsmMapLayersSignedCookieTTLs);

      return mapLayersSignedCookieTTLs[mapLayerConfigId];
    },
});

/**
 * Returns whether there are dynamic layers
 */
export const rsmHasDynamicLayers = selector({
  key: 'rsmHasDynamicLayers',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return !!mapLayerConfigs.length;
  },
});

/**
 * @description These are the ids of all the dynamic layers
 */
export const rsmDynamicLayerIds = selector({
  key: 'rsmDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * @description Returns a map of collectionNames names to mapLayerConfig ids
 */
export const rsmDynamicLayerCollectionNameToIdsMap = selector<{ [key: string]: MapLayerConfig['id'] }>({
  key: 'rsmDynamicLayerCollectionNameToIdsMap',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.reduce((collectionIdMap, mapLayerConfig) => {
      return {
        ...collectionIdMap,
        [mapLayerConfig.collectionName]: mapLayerConfig.id,
      };
    }, {});
  },
});

/**
 * @description Returns a map of mapLayerConfig ids to collectionNames
 */
export const rsmDynamicLayerIdsToCollectionNameMap = selector<{
  [key: MapLayerConfig['id']]: MapLayerConfig['collectionName'];
}>({
  key: 'rsmDynamicLayerIdsToCollectionNameMap',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.reduce((idToCollectionNameMap, mapLayerConfig) => {
      return {
        ...idToCollectionNameMap,
        [mapLayerConfig?.id]: mapLayerConfig?.collectionName,
      };
    }, {});
  },
});

/**
 * This is an array of all the ids for visible dynamic map layers
 */
export const rsmVisibleDynamicLayerIds = selector({
  key: 'rsmVisibleDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const layerVisibility = get(rsmMapLayersVisibility);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id).filter((id) => !!layerVisibility[id]);
  },
});

/**
 * @description Returns a prioritized list of the maplayer ids sorted by popupInfoPriority
 */
export const rsmDynamicLayerPrioritization = selector({
  key: 'rsmDynamicLayerPrioritization',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const newMapLayerConfigs = [...mapLayerConfigs];

    newMapLayerConfigs.sort((firstConfig, secondConfig) => {
      return firstConfig.config?.displayInfo?.popupInfoPriority - secondConfig?.config?.displayInfo?.popupInfoPriority;
    });

    return newMapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * @description Returns the Map Layer Config with the given id
 */
export const rsmMapLayerConfigById = selectorFamily<MapLayerConfig, MapLayerConfig['id']>({
  key: 'rsmMapLayerConfigById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfigs = get(rsmMapLayerConfigs);

      return mapLayerConfigs.find((mapLayerConfig) => mapLayerConfig.id === mapLayerConfigId);
    },
});

/**
 * @description Returns the template type for the Map Layer Config with the given id
 */
export const rsmTemplateTypeById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateTypeById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.name;
    },
});

/**
 * @description Returns the main color to use for the styling template for the Map Layer Config with the given id
 */
export const rsmTemplateColorById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateColorById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.color;
    },
});

/**
 * @description Returns the promote id for the Map Layer Config with the given id
 */
export const rsmPromoteIdById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmPromoteIdById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.source?.promoteId;
    },
});

/**
 * @description Returns the custom styling objects for the layer
 */
export const rsmLayerStylingById = selectorFamily<LayerProps[], MapLayerConfig['id']>({
  key: 'rsmLayerStylingById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layers;
    },
});

/**
 * @description Returns the tiles array for the layer
 * This requests tiles from tiles.<env>.pano.ai created via geoService 2.0
 *
 */
export const rsmTilesById = selectorFamily({
  key: 'rsmTilesById',
  get:
    (mapLayerConfigId: MapLayerConfig['id']) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      if (!mapLayerConfig) {
        return [];
      }

      return [`${TILES_URL}/geo/${mapLayerConfig?.collectionName}/{z}/{x}/{y}.pbf`];
    },
});

/**
 * @description Returns the icon type to use for the Map Layer with the given id
 */
export const rsmMapIconById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmMapIconById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.icon;
    },
});

/**
 * @description Returns the title for the given MapLayerConfig
 */
export const rsmTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTitleById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig?.config?.displayInfo?.title || '';
    },
});

/**
 * @description Returns the subtitle for the given MapLayerConfig
 */
export const rsmSubTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmSubTitleById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.displayInfo?.subTitle || '';
    },
});

/**
 * @description Returns the icon to use for the given MapLayerConfig
 */
export const rsmIconById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmIconById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.displayInfo?.icon;
    },
});

/**
 * @description Get Map Layer Config Id by the collectionName
 */
export const rsmMapLayerConfigIdByCollectionName = selectorFamily<MapLayerConfig['id'], string>({
  key: 'rsmMapLayerConfigIdByCollectionName',
  get:
    (collectionName) =>
    ({ get }) => {
      const collectionNameToIdMap = get(rsmDynamicLayerCollectionNameToIdsMap);

      return collectionNameToIdMap[collectionName];
    },
});

/**
 * @description Related to Giving info for each nearby asset in the Nearby Asset List
 * @returns an array of keys for all the properities to display information
 */
export const rsmNearbyAssetInfoById = selectorFamily<string[], string>({
  key: 'rsmNearbyAssetInfoById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig?.config?.displayInfo?.properties?.map((property) => property.property.toString()) || [];
    },
});

/**
 * @returns An array of 'collectionNames' for all the map layer configs that have the Nearby Assets functionality enabled
 */
export const rsmNearByAssetEligibleCollectionNames = selector<string[]>({
  key: 'rsmNearByAssetEligibleCollectionNames',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs?.reduce((collectionNames, currentConfig) => {
      if (!currentConfig.nearbyAssetsEnabled) {
        return collectionNames;
      }

      return [...collectionNames, currentConfig.collectionName];
    }, []);
  },
});
