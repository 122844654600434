import PanoApi from 'data/proxyApi/panoApi';
import { WEATHER_CONDITIONS_URL, WEATHER_PROVIDER_URL } from 'integrations/weatherStations';
import { WeatherProvider, WeatherStationDataResponse } from 'integrations/weatherStations/types';

/**
 * Parameters for fetching weather station data
 */
export interface FetchWeatherDataParams {
  /** Bounding box coordinates in format "minLat,minLon,maxLat,maxLon" */
  bbox?: string;
  /** ISO timestamp to get conditions nearest to */
  nearestToTime?: string;
  /** ID of specific weather station to fetch conditions for */
  weatherStationId?: string;
  /** Page number for pagination */
  pageIndex?: number;
  /** Number of results per page */
  pageSize?: number;
}

/**
 * @description API for fetching weather stations data
 * @extends BaseApi
 */
class WeatherStationApi extends PanoApi {
  constructor() {
    super();
  }

  /**
   * @description Fetches weather conditions for all stations within a bounding box
   * @returns Paginated list of weather stations and their conditions
   * @throws Error if API request fails
   */
  async fetchWeatherData({
    bbox,
    nearestToTime,
    weatherStationId,
    pageIndex,
    pageSize,
  }: FetchWeatherDataParams): Promise<WeatherStationDataResponse> {
    try {
      const { data } = await this.api.get(WEATHER_CONDITIONS_URL, {
        params: {
          bbox,
          nearestToTime,
          weatherStationId,
          pageIndex,
          pageSize,
        },
      });

      return data;
    } catch (error) {
      console.error('[Pano] API Error: /weather-conditions', error);

      throw new Error(`[Pano] API Error: ${error}`);
    }
  }

  /**
   * @description Fetches list of weather providers the user has access to either from local storage or API
   * @returns Array of provider names the user has access to
   * @throws Error if API request fails
   */
  async fetchWeatherProviders(): Promise<WeatherProvider[]> {
    try {
      const { data: providers } = await this.api.get(WEATHER_PROVIDER_URL);

      return providers;
    } catch (error) {
      console.error('[Pano] Failed to fetch weather providers:', error);

      throw new Error(`[Pano] API Error: ${error}`);
    }
  }
}

export default WeatherStationApi;
