import license from 'assets/weather-layers-license.json';
import { reportErrorMessage } from 'pano360/utils';
import * as WeatherLayers from 'weatherlayers-gl';
import * as WeatherLayersClient from 'weatherlayers-gl/client';

const WeatherLayersToken = process.env.REACT_APP_WEATHER_LAYERS_TOKEN;
/** Wrapper around WeatherLayersClient methods  */
export default class WeatherLayersApi {
  unitSystem: WeatherLayers.UnitSystem;
  #client: WeatherLayersClient.Client;

  constructor() {
    this.#client = new WeatherLayersClient.Client({
      accessToken: WeatherLayersToken,
      datetimeInterpolate: true,
      unitSystem: WeatherLayers.UnitSystem.IMPERIAL,
    });
    WeatherLayers.setLicense(license);
  }

  async fetchLayer(dataset: string, unitSystem: WeatherLayers.UnitSystem) {
    try {
      // calculate the datetime range for visualization as 0-24 forecast
      const datetimeRange = WeatherLayers.offsetDatetimeRange(new Date().toISOString(), 0, 24);

      const datasetMetadata = await this.#client.loadDataset(dataset, {
        unitSystem: unitSystem,
      });
      const { datetimes } = await this.#client.loadDatasetSlice(dataset, datetimeRange);
      const closestDatetime = WeatherLayers.getClosestStartDatetime(datetimes, new Date().toISOString());

      const imageData = await this.#client.loadDatasetData(dataset, closestDatetime, {
        unitSystem: unitSystem,
      });

      return { imageData, datasetMetadata };
    } catch (err) {
      console.error(err);
      reportErrorMessage(JSON.stringify(err));
      throw err;
    }
  }
}
