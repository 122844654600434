import { AxiosInstance } from 'axios';

import { authApi } from '../../config/api';

/**
 * @description Base class for all API requests. Use this to reduce boilerplate code for making API requests.
 * @extends AxiosInstance
 * @property {AxiosInstance} api - The Axios instance for making API requests
 */
export default class PanoApi {
  api: AxiosInstance;

  constructor() {
    this.init();
  }

  async init(): Promise<void> {
    this.api = await authApi();
  }

  setAxiosInstanceApi(axiosInstance: AxiosInstance): void {
    this.api = axiosInstance;
  }
}
