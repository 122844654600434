/**
 * This file is for managing authentication
 * authentication - Confirms users are who they say they are
 * - Restricts front-end content based on their profile
 */

import { SECRET_ANONYMOUS, USER_PAGE_WITH_FULL_AUTH_FROZEN } from 'config/base';
import { localStorageEffect, rspIsStationInOz } from 'data';
import { rsiSelectedStationId } from 'data/store/incidentStore';
import { rsiFetchIncidentLabelById } from 'data/store/incidentStore/incidentById';
import { rssIsStationOzCapable, rssIsStationOzRequestableById, rssQueryStationById } from 'data/store/stationStore';
import _intersection from 'lodash/intersection';
import _some from 'lodash/some';
import _uniq from 'lodash/uniq';
import { atom, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import {
  exactTrackedUser,
  GlobalPermission,
  INCIDENT_LABEL,
  ORG_TYPES,
  OrgInfo,
  OrgPermission,
  PanoUser,
  RsaHasPermissionParams,
  TrackedUser,
} from 'types';

import { rsaUser, rsaUserId, rsaUserOrg } from '../user/user';

/**
 * Whether any of the users orgs is `Pano` type
 */
export const rsaIsSomeOrgPanoType = selector<boolean>({
  key: 'rsaIsSomeOrgPanoType',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);
    const orgs: OrgInfo[] = user?.orgs || [];

    return !!orgs.find((org) => org?.type === ORG_TYPES.PANO);
  },
});

/**
 * Returns the tracked user, which is used by our analytics tools
 */
export const rsaTrackedUser = selector<TrackedUser>({
  key: 'rsaTrackedUser',
  get: ({ get }) => {
    const userId: string = get(rsaUserId);
    const userOrg: OrgInfo = get(rsaUserOrg);

    return userId && userOrg?.name && userOrg.type
      ? exactTrackedUser({
          user_id: userId || '',
          user_org_name: userOrg?.name || '',
          user_org_type: userOrg?.type || '',
        })
      : null;
  },
});

export const rsaIsSystemSuper = selector<boolean>({
  key: 'rsaIsSystemSuper',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);

    return user?.super;
  },
});

/**
 * @Returns whether or not the user has the given org permission, and the ability to access the station
 */
export const rsaHasPermission: (_params: RsaHasPermissionParams) => RecoilValueReadOnly<boolean> = selectorFamily<
  boolean,
  RsaHasPermissionParams
>({
  key: 'rsaHasPermission',
  get:
    ({ permission, stations }: RsaHasPermissionParams) =>
    ({ get }): boolean => {
      const isSystemSuper = get(rsaIsSystemSuper);

      if (isSystemSuper) {
        return true;
      }
      const user = get(rsaUser);

      if (stations) {
        const orgsId = (user?.orgs || []).map((org) => org.id);
        // org ids in all given stations
        const stationOrgIds = _uniq(
          stations.reduce((ids: string[], station) => {
            return [...ids, ...(station?.orgs || [])];
          }, []),
        );
        // intersect the station orgs with current account orgs
        const sameOrgIds = _intersection(orgsId, stationOrgIds);

        if (sameOrgIds.length > 0) {
          const sameOrgs: OrgInfo[] = sameOrgIds.map((id) => user?.orgs.find((org) => org.id === id));

          // At least one org with target permission
          return _some(sameOrgs, (org) => (org?.orgPermissions || []).includes(permission));
        }

        return false;
      }

      return _some(user?.orgs || [], (org) => (org?.orgPermissions || []).includes(permission));
    },
});

/**
 * Whether the user is logged in by access token
 * - This means the user went to a shared page, and the user isn't logged int
 */
export const rsaIsLoggedInByToken: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaIsLoggedInByToken',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);

    return !!user?.bearer && user?.firstName === SECRET_ANONYMOUS;
  },
});

/**
 * Whether a user is authenticated with a username/password
 */
export const rsaIsFullAuth: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'rsaIsFullAuth',
  get: ({ get }) => {
    const user: PanoUser = get(rsaUser);

    return !!user?.bearer && user?.firstName !== SECRET_ANONYMOUS;
  },
});

/**
 * This is the url of the shared page a user visited that caused permissions to be frozen
 * - **frozen** - Visiting shared incident for an org that this user doesnt belong to
 * - If empty user is not on a frozen page
 */
export const rsaPageWithFullAuthFrozen = atom<string>({
  key: 'rsaPageWithFullAuthFrozen',
  default: '',
  effects: [localStorageEffect<string>(USER_PAGE_WITH_FULL_AUTH_FROZEN, null)],
});

/**
 * Returns whether the user has permission to control OZ for the given station ID
 */
export const rsaHasOzAccessByStationId = selectorFamily<boolean, number>({
  key: 'rsaHasOzAccessByStationId',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));

      const hasOzAccessToStation = get(
        rsaHasPermission({
          stations: [station],
          permission: OrgPermission.OzController,
        }),
      );

      return hasOzAccessToStation;
    },
});

/**
 * Returns whether the user is able to request OZ for the given incidentId for the selected station
 */
export const rsaIsRequestOZEnabledByIncidentId = selectorFamily<boolean, number>({
  key: 'rsaIsRequestOZEnabledByIncidentId',
  get:
    (incidentId) =>
    ({ get }) => {
      if (!incidentId) {
        return false;
      }

      const stationId = get(rsiSelectedStationId);
      const label = get(rsiFetchIncidentLabelById(incidentId));

      const isConfirmedIncident = label === INCIDENT_LABEL?.CONFIRMED;
      const hasOzAccessToStation = get(rsaHasOzAccessByStationId(stationId));
      const isStationOzCapable = get(rssIsStationOzCapable(stationId));
      const isStationInOz = get(rspIsStationInOz(stationId));
      const isStationOzRequestableById = get(rssIsStationOzRequestableById(stationId));

      return (
        isConfirmedIncident &&
        !hasOzAccessToStation &&
        !isStationInOz &&
        isStationOzCapable &&
        isStationOzRequestableById
      );
    },
});

/**
 * Returns whether the user has permission to attach or detach incidents
 */
export const rsaHasAttachIncidentPermission = selector<boolean>({
  key: 'rsaHasAttachIncidentPermission',
  get: ({ get }) => {
    const isSystemSuper = get(rsaIsSystemSuper);

    if (isSystemSuper) {
      return true;
    }

    const user = get(rsaUser);

    const requiredPermissions = [
      GlobalPermission.AttachIncident,
      GlobalPermission.ReadIncident,
      GlobalPermission.WriteIncident,
    ];

    return requiredPermissions.every((permission) => user?.permissions?.includes(permission));
  },
});

/**
 * Returns whether the user has the required permissions to view the feature
 * This is used in the GlobalPermissionGuard component
 */
export const rsaHasGlobalPermission = selectorFamily<boolean, GlobalPermission[]>({
  key: 'rsaHasGlobalPermission',
  get:
    (requiredPermissions) =>
    ({ get }) => {
      const isSystemSuper = get(rsaIsSystemSuper);

      if (isSystemSuper) {
        return true;
      }

      const user = get(rsaUser);

      return requiredPermissions.every((permission) => user?.permissions?.includes(permission));
    },
});
