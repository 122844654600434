import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  tabPanelDynamic: {
    padding: 0,
  },
  tabPanelForecast: {
    padding: 0,
    marginTop: -1.5,
  },
  tabListContainer: {
    marginTop: 1.5,
    gap: 1.5,
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;
