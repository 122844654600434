import { SxProps, Theme } from '@mui/material';

interface StyleProps {
  [key: string]: SxProps<Theme>;
}

export const styles: StyleProps = {
  tab: (theme: Theme) => ({
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.greys.dark,
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightSemiBold,
      color: theme.palette.greys.dark,
    },
  }),
};

export default styles;
