import { VIEW_ADMIN_CENTER_STATIONS_PERMISSIONS } from 'config/constants';
import { rsaIsSystemSuper, rsaUser } from 'data/store/authStore';
import { selector } from 'recoil';
import { GlobalPermission } from 'types';

/**
 * Whether or not the user has permission to view the Admin Center
 *
 * @note These permissions will need to be updated as other resources are added to the Admin Center
 * e.g. Permission to view Organizations, Users, etc.
 * If the user has view permission for any resource, they should be able to view the Admin Center
 */
export const rsaHasViewAdminPermission = selector<boolean>({
  key: 'rsaHasViewAdminPermission',
  get: ({ get }) => {
    const isSystemSuper = get(rsaIsSystemSuper);

    if (isSystemSuper) {
      return true;
    }

    const user = get(rsaUser);

    const hasViewAdminStationsPermission = VIEW_ADMIN_CENTER_STATIONS_PERMISSIONS.every((permission) =>
      user?.permissions?.includes(permission),
    );

    return hasViewAdminStationsPermission;
  },
});

/** Whether or not the user has the w_ec permission required to edit a station */
export const rsaHasWriteStationPermission = selector<boolean>({
  key: 'rsaHasWriteStationPermission',
  get: ({ get }) => {
    const isSystemSuper = get(rsaIsSystemSuper);

    if (isSystemSuper) {
      return true;
    }

    const user = get(rsaUser);

    return user?.permissions?.includes(GlobalPermission.WriteStation);
  },
});
