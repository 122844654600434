import { DEFAULT_PLAYER_PERIOD } from 'config/constants';
import { rsaAccountUnit, rsaUserCoordinateSystem } from 'data/store/authStore';
import {
  get180Dois,
  getCoordinatesForDisplay,
  getDistance,
  getGDACoordinatesFromLonLat,
  getIsStatusDisabled,
  getMinStationVisibilityInM,
  getStationImageDimension,
  getStationThumbnailPaths,
  stationWithOpticalZoom,
  stringifyGDACoordinates,
  stringifyLocation,
  stringifyLonLat,
} from 'pano360/utils';
import { selectorFamily } from 'recoil';
import { CoordinatesForDisplay, CoordinateSystem, LonLat, Station } from 'types';

import { rssUserStationsMap } from '../stationStore';

export const rssQueryStationById = selectorFamily<Station, number>({
  key: 'rssQueryStationById',
  get:
    (id) =>
    ({ get }) => {
      const userStationsMap = get(rssUserStationsMap);

      return userStationsMap[id] || null;
    },
});

/**
 * Whether the station is calibrated to provide estimated ranges using
 * single station incident range estimation
 * */
export const rssIsStationSSILRCalibratedByID = selectorFamily<boolean, number>({
  key: 'rssIsStationCalibratedByID',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return !!station?.cameraModelModifyTime;
    },
});

/**
 * Returns whether or not to show the Camera Offline Thumbnail for a station in the Station List
 */
export const rssIsStationThumbnailOfflineById = selectorFamily<boolean, number>({
  key: 'rssIsStationThumbnailOfflineById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station) {
        return true;
      }

      return getIsStatusDisabled(station.status) || !station?.lastSequence;
    },
});

/**
 * The stringified location of the station
 */
export const rssStationStringifiedLocationById = selectorFamily<string, number>({
  key: 'rssStationStringifiedLocationById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return stringifyLocation(station);
    },
});

/**
 * The 2D size of the images for the pano
 * @returns [panoWidth / dois / ratio, panoHeight]
 */
export const rssStationImageDimensionsById = selectorFamily<[number, number], number>({
  key: 'rssStationImageDimensionsById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station) {
        return null;
      }

      return getStationImageDimension(station);
    },
});

/**
 * @returns an array of urls of the images that can be used to create the thumbnail
 */
export const rssStationThumbnailPathsById = selectorFamily<string[], number>({
  key: 'rssStationThumbnailPathsById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station) {
        return null;
      }

      return getStationThumbnailPaths(station, '4');
    },
});

/**
 * The number of images to use to create the thumbnail
 */
export const rssStationThumbnailCount = selectorFamily<number, number>({
  key: 'rssStationThumbnailCount',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station) {
        return null;
      }

      return get180Dois(station);
    },
});

/**
 * The name of the station
 */
export const rssStationNameById = selectorFamily<string, number>({
  key: 'rssStationNameById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return station?.name;
    },
});

export const rssStationLocationById = selectorFamily<string, number>({
  key: 'rssStationLocationById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return stringifyLocation(station);
    },
});

export const rssStationElevationById = selectorFamily<string, number>({
  key: 'rssStationElevationById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const accountUnit = get(rsaAccountUnit);

      return getDistance(accountUnit, station?.elev || 0, false);
    },
});

export const rssStationLonLatById = selectorFamily<LonLat, number>({
  key: 'rssStationLonLatById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return {
        lat: station?.lat,
        lon: station?.lon,
      };
    },
});

export const rssIsStationOfflineById = selectorFamily<boolean, number>({
  key: 'rssIsStationOfflineById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station?.lastSequence?.[1]) {
        return true;
      }

      return Date.now() / 1000 - DEFAULT_PLAYER_PERIOD > (station?.lastSequence[1] || 0);
    },
});

export const rssIsStationCalibratedById = selectorFamily<boolean, number>({
  key: 'rssIsStationCalibratedById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return !!station?.cameraModelModifyTime;
    },
});

/**
 * @returns {boolean} Wether the station is disabled
 * Disabled = 'inactive' | 'assemble'
 */
export const rssIsStationDisabledById = selectorFamily<boolean, number>({
  key: 'rssIsStationDisabledById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      if (!station) {
        return true;
      }

      return getIsStatusDisabled(station.status);
    },
});

/**
 * @param id - the Id of the station to get the calculated name for
 * @returns - The name of the station, and the status if it is inactive (Inactive)
 */
export const rssStationNameWithStatusById = selectorFamily<string, number>({
  key: 'rssStationNameWithStatusById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));
      const isStationDisabled = get(rssIsStationDisabledById(id));

      return `${station?.name}${isStationDisabled ? ' (Inactive)' : ''}`;
    },
});

/**
 * Returns whether a given station is OZ capable
 */
export const rssIsStationOzCapable = selectorFamily<boolean, number>({
  key: 'rssIsStationOzCapable',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));

      return stationWithOpticalZoom(station);
    },
});

/**
 * @description Returns the Station's Primary Coordinates as a string
 * - If the incident is gcs: GDA2020 return GDA coords
 * - If the incident is gcs: WGS84 return WGS coords
 */
export const rssStationPrimaryCoordinatesById = selectorFamily<string, number>({
  key: 'rssStationPrimaryCoordinatesById',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      const lonLat = { lat: station?.lat, lon: station?.lon };

      if (!station) {
        return null;
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat));
      }

      return stringifyLonLat(lonLat);
    },
});

/**
 * @description Returns stringified versions of all coordinates available for that station given the users org
 * - If the incident is gcs: GDA2020 include GDA coords
 * - Always return WGS84 in the list
 */
export const rssStationAllCoordinatesById = selectorFamily<CoordinatesForDisplay[], number>({
  key: 'rssStationAllCoordinatesById',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));
      const coordinateSystem = get(rsaUserCoordinateSystem);
      const lonLat = { lat: station?.lat, lon: station?.lon };

      return getCoordinatesForDisplay(lonLat, coordinateSystem);
    },
});

/**
 * @description whether the station with the given ID enables users to "request" an OZ session
 */
export const rssIsStationOzRequestableById = selectorFamily<boolean, number>({
  key: 'rssIsStationOzRequestableById',
  get:
    (id) =>
    ({ get }) => {
      const station = get(rssQueryStationById(id));

      return !!station?.ozRequestAllowed;
    },
});

/**
 * @description - Returns the stations visibility in meters
 * @note The stations visibility is definied by the minimum visibility of the staiton's cameras
 */
export const rssStationVisibilityInMetersById = selectorFamily<number, number>({
  key: 'rssStationVisibilityById',
  get:
    (stationId) =>
    ({ get }) => {
      const station = get(rssQueryStationById(stationId));

      return getMinStationVisibilityInM(station);
    },
});
