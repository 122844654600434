import React from 'react';
import InfoIcon from 'assets/icons/info.svg';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import styles from './DisclaimerTooltip.styles';

interface TooltipProps {
  customMessage?: string;
}

/** A tooltip to show disclaimer text about location data, renders a ⓘ, on hover, renders the popup */
export const DisclaimerTooltip = ({ customMessage }: TooltipProps) => {
  const disclaimerText = (
    <Box sx={styles.tooltip} data-cy='disclaimer-text'>
      {customMessage ? (
        <Typography variant='body2'>{customMessage}</Typography>
      ) : (
        <Typography variant='body2' component='h3' sx={styles.tooltipHeader}>
          Location Disclaimer
        </Typography>
      )}
      <Typography variant='body2'>
        All location information (e.g. bearing, latitude, longitude, and viewsheds) is estimated only.
      </Typography>
      <Typography variant='body2'>
        Actual location may vary. Please exercise your independent judgment and use all available information at your
        disposal to make informed decisions.
      </Typography>
    </Box>
  );

  return (
    <Tooltip
      title={disclaimerText}
      arrow
      slotProps={{
        popper: {
          disablePortal: true,
        },
      }}
    >
      <IconButton component='span' sx={styles.infoIcon} size='small'>
        <Box component='img' src={InfoIcon} alt='Additional Informtion' sx={styles.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default DisclaimerTooltip;
