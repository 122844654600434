import React from 'react';
import { Tab, TabContext, TabList, TabPanel } from 'componentLibrary/Tabs';
import WeatherMapLayersMenu from 'components/Map/MapLayersMenu/WeatherMapLayersMenu';
import { WeatherDisplayType } from 'integrations/weatherStations/config/constants';
import { rswWeatherTabSelection } from 'integrations/weatherStations/data';
import { useRecoilState } from 'recoil';

import Box from '@mui/material/Box';

import WeatherStationMenu from '../WeatherStationMenu';

import styles from './WeatherStationLayout.styles';

/**
 *
 * @returns A React component that displays a tabbed interface for two weather display option panels
 */
const WeatherStationLayout = () => {
  const [weatherTabSelection, setWeatherTabSelection] = useRecoilState(rswWeatherTabSelection);

  const handleChange = (event: React.SyntheticEvent, newValue: WeatherDisplayType) => {
    setWeatherTabSelection(newValue);
  };

  return (
    <TabContext value={weatherTabSelection} data-cy='weather-menu-tab'>
      <Box sx={styles.tabListContainer}>
        <TabList styleVariant='contained' centered onChange={handleChange} aria-label='Weather Display Toggle'>
          <Tab label='Weather Stations' value={WeatherDisplayType.DYNAMIC_OPTION} />
          <Tab label='Forecast' value={WeatherDisplayType.FORCAST} />
        </TabList>
        <TabPanel sx={styles.tabPanelDynamic} value={WeatherDisplayType.DYNAMIC_OPTION}>
          <WeatherStationMenu />
        </TabPanel>
        <TabPanel sx={styles.tabPanelForecast} value={WeatherDisplayType.FORCAST}>
          <WeatherMapLayersMenu />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default WeatherStationLayout;
