import React from 'react';
import { rswActiveWeatherStationProvider, rswShowingWeatherStations } from 'integrations/weatherStations';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import styles from './WeatherStationMenu.styles';
import WeatherStationMenuLabel from './WeatherStationMenuLabel';

/**
 * @returns A React component that displays a switch to toggle the display of weather stations on the map.
 */
const WeatherStationMenu = () => {
  const activeWeatherStationProvider = useRecoilValue(rswActiveWeatherStationProvider);
  const setWeatherStationsVisible = useSetRecoilState(rswShowingWeatherStations);
  const showingWeatherStations = useRecoilValue(rswShowingWeatherStations);

  if (!activeWeatherStationProvider) {
    return null;
  }

  return (
    <Box data-cy='weather-station-map-menu-item' sx={styles.container}>
      <FormControl component='fieldset' variant='standard'>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showingWeatherStations}
                onChange={() => setWeatherStationsVisible(!showingWeatherStations)}
                name='WeatherStationToggle'
              />
            }
            label={<WeatherStationMenuLabel activeWeatherStationProvider={activeWeatherStationProvider} />}
          />
        </FormGroup>
      </FormControl>
      <Typography variant='body2'>
        {`${activeWeatherStationProvider.displayName} data is private to your organization. Pano is not responsible for
        this data. Use at your own risk.`}
      </Typography>
    </Box>
  );
};

export default WeatherStationMenu;
